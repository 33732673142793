// temporary whatsapp accounts list from META

export default [
  // '5cc32c49-7d10-4c2c-8aac-9dce25520d73', // conta teste, use só em localhost
  '142a07bd-25d6-4681-989d-d0cb0b4728ce',
  'cb83acd8-9746-4aeb-bda5-5d6bdffae4c3',
  '1c7c571c-5dfc-466c-925b-1c9cf3f93b41',
  'b0f68367-11b4-4796-8b37-5de7388fc6d7',
  '87449f80-72bc-4a81-96b3-aa2e3ed6f6df',
  'fbf761a7-92b7-4aeb-bb34-ec26d1da8971',
  'cdf3996d-b141-44ab-849a-9121d3b2ddce',
  '4a212df8-d961-45e7-b484-60a311a57fe2', // Label Tech
  'ea96a910-de46-4a3c-ae82-12344df70551', // High Link
  '13962c1b-24c5-48a1-95ba-6b7112d8c3d3',
  '7b85db65-21d6-4924-8bb7-ca94f0aa88a2',
  '56ba9e44-ff98-4e86-ad43-5500e77b8ce1', // SETFIN CONSULTORIA LTDA
  'c5209a38-7bae-4b7d-abb0-d785c00c67cc', // Comprosper
  'ffe0ae8e-0dfc-49cd-8842-7a8ad6a1f849',
  'd577caf6-a7d9-4a5e-a22c-475a3b3c041f', // MATRI
  '2ea8eb30-a662-43bc-b834-ed88632adbb7', // DIDIO
  'd77c2537-d749-4068-8fff-9aace524fbd0', // RM FARMA
  '1edb4a0f-2a8a-4f8c-9de7-523f41a264f1', // Quiroplus
  'c0af7a99-a327-4f92-8233-6bd501a422d9', // Kankei
  '9d34e637-2073-47f7-8550-a0fc40bb35d9', // M&M GOMES SUPERMERCADOS
  'faff9aa7-bb4d-482b-ad88-d6eddf044333', // QUALICONSIG
  'cb4e1950-115e-4bd0-becd-9400f5841b7b', // H V DA SILVA COMERCIO - COMPAG
  'a3b32e62-4b01-482e-ab46-c0ce33c66ef1', // AXEM MEDICINA INTELIGENTE LTDA
  'b6cd8b63-64e3-4ea6-9c57-4bbd3c8b9401', // FreitasBank
  '4e53295b-b78e-421a-bbdb-9981d88008c5', // Socarrao Web Solutions Ltda - Epp
  'cebb960c-a2b3-4621-86af-a3494eaca8dd', // RS ALMEIDA LTDA - AO AVESSO
  '3fdc40b4-822f-45da-a002-5a95317d63a8', // WALDECK ADVOGADOS ASSOCIADOS S/S
  '9c4c871f-dd63-455f-b5b9-743f168a0a3c', // 10 DB Instituto de Música
  '3831265f-9d4e-4749-8a1d-23475d278103', // Mint Soluções
  '40bd3e2f-0e5e-4f8e-a0a3-df0bd1771062', // FABRICA DE DOCES LEDUR LTDA
  '8b7687fa-38ef-4dff-a13e-4541d6df3951', // Auto Peça 3G
  '0e7b5afd-6e19-4584-badb-5364958dcfc7', // Digiteam
  '1d905ede-1f26-4cd5-a81a-52e358915d65', // ADV Morais
  'ab3400e2-c8dd-4ef3-9fc3-c205f66fe47d', // HOTEL IN BOX
  '52810633-b669-43a0-8a5c-3a23281536ae', // Serinews
  '9e327207-6de7-4ab2-9e90-4fe93145e037', // YLY Benefícios
  '359c95d8-a201-4dc9-ad40-66606e9fddab', // Pós-Vendas-Permetal
  '767b188c-f685-49db-8e4b-0f123ae80128', // Groove Bar
  '899b637e-9c66-4ef8-9e68-2061bacdf0ce', // TLC Worldwide LATAM
  '7888967e-a5e4-4b8a-9afd-d05ae478984e', // Denavita
  '2b65ce23-8e01-44c1-abdb-e0bf8d2474de', // SVO-OABRS
  '74502c4d-224f-4727-8e1d-ac35865bc9ad', // CONECTA SUITE
  'ec44bf47-fd26-4ac2-8401-5e6f20809672', // Sofá Center
  '85be951a-a38c-4af4-aa51-dd37e04798d4', // Yes Energia Solar
  '86b65a73-1be2-4446-a683-6b5e3a9b104d', // Digitalks
  'abd08ac8-8545-4368-a13b-1ac079485db6', // Yup Chat- Eventos
  'bd9dfa1a-b7c1-4c07-a431-ce8726a5d985', // Ingresse
  '2e675fa4-d71a-4eb4-87b6-2e787f6e5ebc', // Yup AI
  'fac3e79e-7b0a-439c-b1ec-9218477490a1', // Onom Design
  '08a4de22-3217-43b5-bdf9-66ac83b8f7db', // Dotz
  'e983b7ef-6526-4988-86e7-1656e0cdf255', // SUPERLOGICA - MORAIS ADVOGADOS ASSOCIADOS
];
